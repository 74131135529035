<template>
    <a href="javascript:void(0);"
        data-toggle="popover"
        title="Verfügbare Sonderangebote"
        data-trigger="click"
        data-placement="top"
        data-html="true"
        :data-content="getOfferText">
        <span class="offer-left">Verfügbare Sonderangebote</span>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    import {
    	getVoucher
    }
    from 'voUtils/VoucherStore.js';

    export default {
    	voVueComponent: 'offer-help',
    	props: {
    		offers: Array
    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    		$('body').on('click', function(e) {
    			$('[data-toggle=popover]').each(function() {
    				if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
    					(($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false;
    				}
    			});
    		});
    	},
    	methods: {
    		getVoucherCode: function() {
    			var voucher = getVoucher();
    			if (voucher) {
    				return voucher.code;
    			}
    			return null;
    		},
    		checkOfferAvailability: function(type, fromDate, tillDate, minStay, timeSpan) {
    			let today = DateEx.interfaceFormat(DateEx.getToday());

    			if (type && fromDate && tillDate) {
    				let from = DateEx.interfaceFormat(new Date(fromDate));
    				var till = DateEx.interfaceFormat(new Date(tillDate));
    				//console.log('Today::', today, 'from::', from, 'till::', till);
    				if (type === 'LASTMINUTE') {
    					let minFromDate = from;
    					let minTillDate = till;

    					if (timeSpan && timeSpan >= 1) {
    						//The start of the offer must be at least as large as today's date plus the maximum lead time
    						minFromDate = DateEx.interfaceFormat(DateEx.plusDays(today, timeSpan));
    					}
    					//If the offer start date is later than today's date plus the lead time, it will not be displayed.
    					if (DateEx.getDays(from, minFromDate) > 0) {
    						return false;
    					}
    					if (minStay && minStay >= 1) {
    						//Deduct minimum rental period from end of offer
    						minTillDate = DateEx.interfaceFormat(DateEx.plusDays(till, -minStay));
    					}
    					//If the offer end date minus the minimum rental period is later than today's date, it will not be displayed.
    					if (DateEx.getDays(minTillDate, today) < 0) {
    						return false;
    					}

    				}
    			}
    			return true;

    		}

    	},
    	computed: {

    		getOfferText: function() {
    			var offerText = '';
    			if (this.offers) {
    				this.offers.sort((a, b) => (a.fromdate > b.fromdate) ? 1 : -1);
    				var i;
    				offerText = "<ul>";
    				for (i = 0; i < this.offers.length; i++) {
    					var offer = this.offers[i];
    					if (!offer.voucherCode || offer.voucherCode === this.getVoucherCode()) {
    						var offer = this.offers[i];

    						if (this.checkOfferAvailability(offer.type, offer.fromdate, offer.tilldate, offer.minStay, offer.timeSpan)) {

    							offerText += "<li>";

    							offerText += offer.name + " ";
    							if (offer.discountType === 'PERCENT') {
    								offerText += "- " + offer.value / 100 + "% Rabatt ";
    							}

    							if (offer.type === 'EARLYBIRD') {
    								if (offer.timeSpan === 1) {
    									offerText += "bei Anreise in frühestens einem Tag ";
    								} else if (offer.timeSpan > 1) {
    									offerText += "bei Anreise in frühestens " + offer.timeSpan + " Tagen ";
    								}

    							}

    							if (offer.type === 'LASTMINUTE') {
    								if (offer.timeSpan == 1) {
    									offerText += "bei Anreise in spätestens einem Tag ";
    								} else if (offer.timeSpan > 1) {
    									offerText += "bei Anreise in spätestens " + offer.timeSpan + " Tagen ";
    								}

    							}


    							if (offer.fromdate) {
    								offerText += "von " + DateEx.formatDate(this.offers[i].fromdate, undefined, VOFFICE.mlang) + " ";
    							}
    							if (offer.tilldate) {
    								offerText += "bis " + DateEx.formatDate(this.offers[i].tilldate, undefined, VOFFICE.mlang) + " ";
    							}

    							if (offer.minStay > 1) {
    								offerText += "(min. " + offer.minStay + " ÜN)";
    							}

    							offerText += "</li>";
    						}
    					}
    				}
    			}

    			return offerText;
    		}
    	}
    };
</script>